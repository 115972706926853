export const useSummary = () => {
  const user = useSanctumUser<User>();
  const processing = useState<boolean>("processing_summary", () => false);
  const { limits } = useLimits();
  const MAX_LENGTH = user.value?.is_subscribed
    ? SUMMARY_MAX_LENGTH_PRO
    : SUMMARY_MAX_LENGTH_FREE;

  const summariesProcessed = useCookie("summaries_proccessed", {
    // Expires at end of day
    expires: new Date(new Date().setHours(23, 59, 59, 999)),
  });

  const incrementSummariesProcessed = () => {
    if (!summariesProcessed.value) {
      summariesProcessed.value = "1";
    } else {
      summariesProcessed.value = (
        parseInt(summariesProcessed.value) + 1
      ).toString();
    }
  };

  const summariesYoutubeProcessed = useCookie("summaries_youtube_proccessed", {
    // Expires in 1 year
    expires: new Date(new Date().setFullYear(new Date().getFullYear() + 1)),
  });

  const incrementSummariesYoutubeProcessed = () => {
    if (!summariesYoutubeProcessed.value) {
      summariesYoutubeProcessed.value = "1";
    } else {
      summariesYoutubeProcessed.value = (
        parseInt(summariesYoutubeProcessed.value) + 1
      ).toString();
    }
  };

  const summariesTextImagesProcessed = useCookie(
    "summaries_text_images_proccessed",
    {
      // Expires in 1 year
      expires: new Date(new Date().setFullYear(new Date().getFullYear() + 1)),
    },
  );

  const incrementSummariesTextImagesProcessed = () => {
    if (!summariesTextImagesProcessed.value) {
      summariesTextImagesProcessed.value = "1";
    } else {
      summariesTextImagesProcessed.value = (
        parseInt(summariesTextImagesProcessed.value) + 1
      ).toString();
    }
  };

  const userCanProcessSummaries = () => {
    return (
      user.value?.is_subscribed ||
      parseInt(summariesProcessed.value || "0") <
        (limits.value?.free_summary_limit_by_day || 5)
    );
  };

  const userCanProcessSummariesYoutube = () => {
    return (
      user.value?.is_subscribed ||
      parseInt(summariesYoutubeProcessed.value || "0") <
        (limits.value?.free_summary_youtube_limit || 1)
    );
  };

  const userCanProcessSummariesTextImages = () => {
    return (
      user.value?.is_subscribed ||
      parseInt(summariesTextImagesProcessed.value || "0") <
        (limits.value?.free_text_image_limit || 1)
    );
  };

  return {
    MAX_LENGTH,
    summariesProcessed,
    incrementSummariesProcessed,
    processing,
    incrementSummariesYoutubeProcessed,
    incrementSummariesTextImagesProcessed,
    summariesYoutubeProcessed,
    summariesTextImagesProcessed,
    userCanProcessSummaries,
    userCanProcessSummariesYoutube,
    userCanProcessSummariesTextImages,
  };
};
